<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div class="uk-form-controls uk-width-small">
                  <select class="uk-select" id="form-horizontal-select" v-model="filterType">
                    <option :value=null>Select Filter</option>
                    <option :value='"fullname"'>Fullname</option>
                    <option :value='"email"'>Email</option>
                    <option :value='"phone_number"'>Phone Number</option>
                  </select>
                </div>
                <div class="uk-width-medium">
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input :disabled="filterType === null" v-model=query class="uk-input" @keyup.enter="onQueryEnter" type="text" placeholder="Search">
                    </div>
                </div>
            </div>
        </div>

        <span v-if="isLoading" uk-spinner="ratio: 2" class="uk-position-center uk-margin-auto"></span>
        <div class="uk-card uk-card-default uk-margin" v-else>
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-center">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-width-medium">Fullname</th>
                            <th class="uk-text-center uk-width-small">Email</th>
                            <th class="uk-text-center uk-width-1-6">Phone</th>
                            <th class="uk-text-center uk-width-small">Verif Code</th>
                            <th class="uk-text-center">Level</th>
                            <th class="uk-text-center">Role</th>
                            <th class="uk-text-center">Partner Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="users.docs.length>0">
                        <tr v-for="(user, i) in users.docs" :key="i">
                            <td>{{user.fullname || '-'}}</td>
                            <td>{{user.email || '-'}}</td>
                            <td>{{user.phone_number || '-'}}</td>
                            <td>{{user.verification_code || '-'}}</td>
                            <td>{{user.level}}</td>
                            <td>{{rolesName(user.roles)}}</td>
                            <td>
                              <div class="label uk-margin-auto" :style="`background-color: ${user.is_partner ? '#00cc00' : 'gray'}`">
                                {{user.is_partner ? 'Partner' : 'Non Partner'}}
                              </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="7"/>
                </table>
            </div>
            <pagination
                :total-data="users.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="formUserModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">User Form</h2>
            </div>
            <div class="uk-modal-body">

              <div class="uk-margin">
                <label class="uk-form-label">Full Name <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('fullname')}"
                  name="fullname"
                  type="text"
                  v-model="userForm.fullname"
                  v-validate="'required'"
                  placeholder="Full Name">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('fullname')">{{ errors.first('fullname') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label uk-margin-small-right">Login via:</label>
                <label class="uk-form-label uk-margin-small-right">
                  <input
                    class="uk-radio"
                    name="login_via"
                    type="radio"
                    v-bind:value="true"
                    v-model="userForm.login_via_email"
                  >
                    Email
                </label>
                <label class="uk-form-label uk-margin-small-right">
                  <input
                    class="uk-radio"
                    name="login_via"
                    type="radio"
                    v-bind:value="false"
                    v-model="userForm.login_via_email"
                  >
                    Phone number
                </label>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Email <b v-if="userForm.login_via_email" class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('email')}"
                  name="email"
                  type="email"
                  v-model="userForm.email"
                  v-validate="{required: userForm.login_via_email}"
                  placeholder="Email">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('email')">
                  {{ errors.first('email') }} Switch login mode to make this field optional.
                </span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Phone Number <b v-if="!userForm.login_via_email" class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('phone_number')}"
                  name="phone_number"
                  type="text"
                  v-model="userForm.phone_number"
                  v-validate="{required: !userForm.login_via_email}"
                  placeholder="Phone Number">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('phone_number')">
                  {{ errors.first('phone_number') }} Switch login mode to make this field optional.
                </span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label"><span v-if="formIsEdit">Change </span>Password <b v-if="!formIsEdit" class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('password')}"
                  name="password"
                  type="password"
                  v-model="userForm.password"
                  v-validate="{required: !formIsEdit}"
                  placeholder="Password">
                <span v-if="!formIsEdit" class="uk-text-small uk-text-danger" v-show="errors.has('password')">{{ errors.first('password') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Role <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="userForm.roles"
                  placeholder="Select Role"
                  label="name"
                  name="role"
                  track-by="name"
                  v-validate="'required'"
                  :options="role_options">
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('role')">{{ errors.first('role') }}</span>
              </div>

              <div v-if="userForm.roles.name==='Candidate'" class="uk-margin">
                <label class="uk-form-label">Partners</label>
                <multiselect
                  v-model="userForm.partner_ids"
                  placeholder="Type to search company partner"
                  label="name"
                  name="partner"
                  track-by="name"
                  :options="partner_options"
                  :multiple="true"
                  :close-on-select="false"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setPartnerValues"
                >
                </multiselect>
              </div>

              <div v-if="userForm.roles.name==='Employer'" class="uk-margin">
                <label class="uk-form-label">Company</label>
                <multiselect
                  v-model="userForm.company_id"
                  placeholder="Type to search company"
                  label="name"
                  name="company"
                  track-by="name"
                  :options="company_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setCompanyValues"
                >
                </multiselect>
              </div>

              <div v-if="userForm.roles.name==='Employer'" class="uk-margin">
                <label class="uk-form-label">Company Role</label>
                <multiselect
                  v-model="userForm.client_role_id"
                  placeholder="Type to search company role"
                  label="name"
                  name="company"
                  track-by="name"
                  :options="internal_role_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setRoleValues"
                >
                </multiselect>
              </div>

              <div v-if="userForm.roles.name==='Employer'" class="uk-margin">
                <label class="uk-form-label">Office (Property)</label>
                <multiselect
                  v-model="userForm.client_office_id"
                  placeholder="Type to search property"
                  label="name"
                  name="property"
                  track-by="name"
                  :options="office_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setPropertyValues"
                >
                </multiselect>
              </div>

              <div v-if="formIsEdit" class="uk-margin">
                <label class="uk-form-label">Verification Code <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('verification_code')}"
                  name="notes"
                  type="text"
                  v-model="userForm.verification_code"
                  placeholder="Verification Code">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('verification_code')">{{ errors.first('verification_code') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Notes</label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('notes')}"
                  name="notes"
                  type="text"
                  v-model="userForm.notes_superadmin"
                  placeholder="Notes">
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">
                  <input
                    class="uk-checkbox uk-margin-small-right"
                    name="user_verified"
                    type="checkbox"
                    v-model="userForm.user_verified"
                  >
                    User Verified
                </label>
              </div>

            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary" type="button" @click="saveUser">Save</button>
            </div>
          </div>
        </div>

        <div id="formDetailModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Delete User</h2>
                </div>
                <div class="uk-modal-body">
                  <p>Are you sure to delete this user?</p>
                  <p>Name: <b>{{userForm.fullname}}</b></p>
                  <p>Email: <b>{{userForm.email}}</b></p>
                  <p>Phone Number: <b>{{userForm.phone_number}}</b></p>
                  <p>Roles: <b>{{userForm.roles.name}}</b></p>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-danger" type="button" @click="markDelete">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    data() {
        return {
            user_cred: getUserLogin(),
            meta: {
                roles: 'all',
                limit: 100,
                page: 1,
            },
            userForm: {
                fullname: '',
                email: '',
                password: '',
                phone_number: '',
                roles: '',
                partner_ids: [],
                notes_superadmin: '',
                geoloc: null,
                company_id: null,
                client_role_id: null,
                client_office_id: null,
                user_verified: false,
                verification_code: null,
                login_via_email: true
            },
            query: '',
            filterType: null,
            role_options: [
                {name: 'Candidate', value: ['zxc123']},
                {name: 'Employer', value: ['asd123']},
            ],
            company_options: [],
            office_options: [],
            partner_options: [],
            internal_role_options: [],
            isLoading: true,
            isFetching: false,
            formIsEdit: false,
            reportUser: null,
            json: {
                'Full Name': 'fullname',
                'Phone': 'phone_number',
                'Email': 'email',
                'Register At': 'created_at',
                'Address': 'address'
            }
        };
    },
    components: {
        Pagination,
        EmptyTable,
    },
    computed : {
        ...mapGetters({
            users: 'user/users',
            companies: 'company/companies',
            company: 'company/company',
            company_roles: 'company_role/company_roles',
            properties: 'property/properties',
        })
    },
    watch: {
        meta() {
            this.setUserMeta();
        },
    },
    mounted() {
        this.user_cred = getUserLogin();
        this.setUserMeta();
    },
    methods: {
        ...mapActions({
            getUser: 'user/getUser',
            createUser: 'user/createUser',
            updateUser: 'user/updateUser',
            deleteUser: 'user/deleteUser',
            getCompanies: 'company/getCompanies',
            getCompanyDetail: 'company/getCompanyDetail',
            getCompanyRoles: 'company_role/getCompanyRoles',
            getProperties: 'property/getProperties',
            getReportUser: 'user/getReportUser',
        }),
        fullName(data) {
            return formatter.getFullname(data);
        },
        async setUserMeta() {
            await this.getUser(this.meta);
            this.isLoading = false;
        },
        async setPartnerValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.partner_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setRoleValues(query) {
            this.isFetching = true;

            await this.getCompanyRoles({name: query, is_internal: true});
            this.internal_role_options = this.company_roles.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setPropertyValues(query) {
            this.isFetching = true;

            await this.getProperties({name: query});
            this.office_options = this.properties.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        resetMeta() {
            this.meta = {
                roles: 'all',
                limit: 100,
                page: 1,
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        onQueryEnter() {
            this.resetMeta();
            this.meta[this.filterType] = this.query;
            this.setUserMeta();
        },
        editUser(user) {
            this.formIsEdit = true;
            this.fillForm(user);
            this.showModal();
        },
        delUser(user) {
            this.formIsEdit = true;
            this.fillForm(user);
            this.showDeleteModal();
        },
        async markDelete(){
            try {
                this.isLoading = true;
                const response = await this.deleteUser(this.userForm.id);
                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('User Deleted!');
                    this.hideDeleteModal();
                    this.setUserMeta();
                } else {
                    notificationDanger('Failed to delete user!');
                }
            } catch (err) {
                notificationDanger(err);
            }

        },
        async saveUser() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                this.userForm.roles = this.userForm.roles.value;
                this.userForm.company_id = this.userForm.company_id ? this.userForm.company_id.value : null;
                this.userForm.client_role_id = this.userForm.client_role_id ? this.userForm.client_role_id.value : null;
                this.userForm.client_office_id = this.userForm.client_office_id ? this.userForm.client_office_id.value : null;
                this.userForm.partner_ids = this.userForm.partner_ids.map(
                    partner => partner.value
                );
                if (!this.userForm.password) delete this.userForm.password;
                if (!this.userForm.verification_code) delete this.userForm.verification_code;
                if (!this.userForm.email) this.userForm.email = null;
                if (!this.userForm.phone_number) this.userForm.phone_number = null;

                const response = this.formIsEdit
                    ? await this.updateUser(this.userForm)
                    : await this.createUser(this.userForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('User saved!');
                    this.hideModal();
                    this.setUserMeta();
                } else {
                    notificationDanger('Failed to save user.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showModal() {
            await window.UIkit.modal('#formUserModal').show();
        },
        async hideModal() {
            await window.UIkit.modal('#formUserModal').hide();
            this.resetForm();
        },
        async showDeleteModal() {
            await window.UIkit.modal('#formDetailModal').show();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#formDetailModal').hide();
            this.resetForm();
        },
        fillForm(user) {
            try {
                this.userForm.id = user._id;
                this.userForm.fullname = user.fullname;
                this.userForm.email = user.email;
                this.userForm.phone_number = user.phone_number;
                this.userForm.password = user.password;
                this.userForm.notes_superadmin = user.notes_superadmin;
                this.userForm.geoloc = user.geoloc;
                this.userForm.user_verified = user.user_verified;
                this.userForm.verification_code = user.verification_code;
                this.userForm.login_via_email = user.verification_code.toString().length === 6;
                this.userForm.roles = this.role_options.find(
                    e => e.value[0] === user.roles
                );
                this.userForm.client_role_id = user.client_role ? {
                    name: user.client_role.name,
                    value: user.client_role._id
                } : null;
                this.userForm.client_office_id = user.client_office ? {
                    name: user.client_office.name,
                    value: user.client_office._id
                } : null;
                this.userForm.company_id = user.company ? {
                    name: user.company.name,
                    value: user.company._id
                } : null;
                user.partner_ids.forEach(async partner_id => {
                    await this.getCompanyDetail(partner_id);
                    this.userForm.partner_ids.push({
                        name: this.company.name, value: this.company._id
                    });
                });

            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.userForm = {
                fullname: '',
                email: '',
                password: '',
                phone_number: '',
                roles: '',
                partner_ids: [],
                notes_superadmin: '',
                geoloc: null,
                company_id: null,
                user_verified: false,
                verification_code: null,
                login_via_email: true
            };
            this.$validator.reset();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        async fetchReportUser() {
            await this.getReportUser().then((res) => {
                this.reportUser = res;
            }).catch((err) => {
                console.log(err);
                this.reportUser = [];
            });
            return this.reportUser;
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        rolesName(role){
            for (const opt of this.role_options) {
                if (role == opt.value[0]) {
                    return opt.name;
                }
            }
        },
    },
};
</script>
